@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap');


/** Reset*/

*{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

/** css vars */

:root{
    --color-bg : #1f1f38;
    --color-bg-variant: #2c2c6c;
    --color-primary: #4db5ff;
    --color-primary-variant: rgba(77,181,255,0.4);
    --color-white: #fff;
    --color-light : rgba(255,255,255,0.6);
    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
    --transition: all 400ms ease;
}

html{
    scroll-behavior: smooth;
}

::-webkit-scrollbar{
    display: none;
}

body{
    font-family: 'Space Mono', monospace;
        background-color: var(--color-bg);
    color: var(--color-white);
    line-height: 1.7;
    background-image: url('../src/assets/bg-texture.png');
}

/**General***/

.container{
    width: var(--container-width-lg);
    margin: 0 auto;
}
h1,h2,h3,h4,h5{
    font-weight: 500;
}
h1{
    font-size: 2.5rem;
}
section{
    margin-top: 8rem;
}

section > h2, section > h5 {
    text-align: center;
    color: var(--color-light);
}

section >h2 {
    color: var(--color-primary);
    margin-bottom: 3rem;
}

.text-light{
    color: var(--color-light);
    transition: var(--transition);
}
a{
    color: var(--color-primary);
    transition: var(--transition);
}

a:hover{
    color: var(--color-white);
}
.btn{
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

.btn-hover{
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}

.btn-primary{
    background-color: var(--color-primary);
    color: var(--color-bg);
}

img{
    display: block;
    max-width: 100%;
    object-fit: cover;
}
.text-primary{
    color: var(--color-primary);
}

/****Theme*****/

.App.bg-purple{
    background: #66027559;
}
.App.bg-purple .about__me{
    background: linear-gradient(45deg, transparent, rgb(181 139 187), transparent);
}
.App.bg-purple .about__card{
    background-color: #ac46c8;
}
.App.bg-purple .about__card:hover{
    border: 1px solid #d37bec;
}
.App.bg-purple .about__content p{
    color: #fff
}
.App.bg-purple .header__socials svg{
    color: #d17be9;
    transition: all 0.2s ease;
}
.App.bg-purple .header__socials svg:hover{
    color: #cfcfcf;
}
.App.bg-purple .header__socials::after{
    background-color: #d17be9;
}
.App.bg-purple .text-primary{
    color: #d17be9;
}
.App.bg-purple .btn {
    color: #fff;
    border: 1px solid #d17be9;
}
.App.bg-purple .btn-primary {
    background-color: #d17be9;
    color: #fff;
}
.App.bg-purple a{
    color: #d17be9;
}
.App.bg-purple section >h2 {
    color: #d17be9;
}
.App.bg-purple .about__icon{
    color: #d17be9;
}
.App.bg-purple .skills__container > div {
    background-color: #833d93;
}
.App.bg-purple .skills__container > div:hover {
    background-color: #9f48b3;
    border: 1px solid #d17be9 ;
}
.App.bg-purple .skills__container > div h3{
    color: #dca3eb;
}
.App.bg-purple .skills__details svg{
    color: #d17be9;
}
.App.bg-purple .portfolio__item {
    background-color: #833d93;
}
.App.bg-purple .portfolio__item:hover {
    background-color: #9f48b3;
    border: 1px solid #d17be9 ;
}
.App.bg-purple footer {
    background-color: #833d93;
}
.App.bg-purple .footer__copyright{
    color: var(--color-light);
}
/******media queries medium ***/

@media screen and (max-width: 1024px) {
    .container{
        width: var(--container-width-md);
    }
    section{
        margin-top: 6rem;
    }
}
/******media queries medium ***/
@media screen and (max-width: 600px) {
    .container{
        width: var(--container-width-sm);
    }
    section > h2{
        margin-bottom: 2rem;
    }
}